import companyPolicyStyle from "../assets/styles/companypart/companyPolicy.module.css";

const CompanyPolicy = () => {
  return (
    <div className={companyPolicyStyle.companyPolicyContainer}>
      <p className={companyPolicyStyle.headerText}>Company Privacy Policy</p>

      <p className={companyPolicyStyle.sectionHeader}>Introduction</p>
      <p className={companyPolicyStyle.text}>
        Troibits Infotech Private Limited (“We”, “Our”, “Us”, or “Company”), a company registered under the Companies Act, 2013, having its registered office at [Insert Office Address], is committed to protecting the privacy and personal data of individuals who interact with our services. This Privacy Policy outlines how we collect, use, process, and safeguard personal information when you use our website, mobile applications, and related services (“Services”). By accessing or using our Services, you agree to the terms outlined in this Privacy Policy. If you do not agree with these terms, please refrain from using our Services.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Scope & Purpose</p>
      <p className={companyPolicyStyle.text}>
        This Privacy Policy applies to all individuals using our Services, including clients, app users, and visitors to our website. Troibits Infotech Private Limited provides app development, software services, and IT solutions. This Privacy Policy explains how we collect, use, share, and protect the personal data of users when interacting with our platforms or services.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Consent</p>
      <p className={companyPolicyStyle.text}>
        By using our Services or submitting any personal information through our platforms, you provide explicit consent to the collection, use, and disclosure of your personal information in accordance with this Privacy Policy. We encourage you to read this policy carefully before providing your information.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Collection of Personal Information</p>
      <p className={companyPolicyStyle.text}>
        We collect the following types of information to deliver and enhance our Services:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, company details, IP address, device identifiers, project-related information (e.g., app requirements, specifications)</li>
        <li><strong>Technical Information:</strong> Browser type and version, operating system, log information (e.g., access frequency, duration), device information (e.g., screen resolution, network details, application errors)</li>
        <li><strong>Non-Personal Information:</strong> Website traffic statistics, app usage data, anonymous user behavior patterns</li>
      </ul>
      <p className={companyPolicyStyle.text}>
        <strong>Note:</strong> We do <strong>not</strong> collect or store sensitive payment details, such as credit card information, through our Services.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Use of Personal Information</p>
      <p className={companyPolicyStyle.text}>
        We use your personal information for legitimate business purposes, including but not limited to:
      </p>
      <ul>
        <li><strong>Service Delivery:</strong> To provide app development, website creation, maintenance, and other IT solutions.</li>
        <li><strong>Communication:</strong> To send you project updates, respond to inquiries, and provide customer support.</li>
        <li><strong>Improvement & Customization:</strong> To enhance the performance of our Services, including troubleshooting issues and improving user experience.</li>
        <li><strong>Legal Compliance:</strong> To comply with legal obligations or to resolve disputes.</li>
        <li><strong>Marketing:</strong> We may use your information to send you newsletters or promotional materials (with your consent).</li>
      </ul>

      <p className={companyPolicyStyle.sectionHeader}>Sharing of Personal Information</p>
      <p className={companyPolicyStyle.text}>
        We may disclose your personal information in the following circumstances:
      </p>
      <ul>
        <li><strong>Third-Party Service Providers:</strong> We may share your personal data with trusted third-party service providers (e.g., cloud hosting, email services) who assist us in delivering our Services. These providers are contractually bound to use the data only for providing services to us and are not permitted to share or use the data for any other purpose.</li>
        <li><strong>Legal Compliance:</strong> We may share your information if required by law, in legal proceedings, or to respond to government requests, including to protect the rights, property, or safety of Troibits Infotech Private Limited and its users.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction. We will notify you of any such changes.</li>
      </ul>

      <p className={companyPolicyStyle.sectionHeader}>Security of Your Data</p>
      <p className={companyPolicyStyle.text}>
        We implement industry-standard security measures to protect your personal data, including:
      </p>
      <ul>
        <li><strong>Encryption:</strong> Data transmitted between the app and our servers is encrypted using secure protocols (e.g., SSL).</li>
        <li><strong>Access Controls:</strong> We restrict access to your personal data to authorized personnel only.</li>
        <li><strong>Security Audits:</strong> We regularly assess the security of our infrastructure to protect against unauthorized access, alteration, or disclosure.</li>
      </ul>
      <p className={companyPolicyStyle.text}>
        Despite these measures, no system is completely secure, and we cannot guarantee the absolute security of your data.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Data Retention</p>
      <p className={companyPolicyStyle.text}>
        We retain personal data only for as long as is necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law. If you choose to delete your account or uninstall our applications, we will delete your personal data within a reasonable timeframe.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>User Rights</p>
      <p className={companyPolicyStyle.text}>
        You have the following rights regarding your personal data:
      </p>
      <ul>
        <li><strong>Access:</strong> You have the right to request a copy of the personal data we hold about you.</li>
        <li><strong>Correction:</strong> You can request corrections to any inaccuracies in your data.</li>
        <li><strong>Deletion:</strong> You may request that we delete your personal data, subject to any legal obligations we may have.</li>
        <li><strong>Withdrawal of Consent:</strong> You may withdraw your consent to the processing of your personal data at any time. This may affect your ability to use certain features of our Services.</li>
      </ul>
      <p className={companyPolicyStyle.text}>
        To exercise any of these rights, please contact us at <a href="mailto:contact@troibits.com">contact@troibits.com</a>.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Cookies</p>
      <p className={companyPolicyStyle.text}>
        Our Services use cookies and other tracking technologies to improve user experience and gather analytics. Cookies may store information about your preferences and login status. You can disable cookies in your browser settings, but this may limit some functionality of our Services.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Third-Party Links</p>
      <p className={companyPolicyStyle.text}>
        Our website and Services may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites. We encourage you to review their privacy policies before providing any personal information.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>International Data Transfers</p>
      <p className={companyPolicyStyle.text}>
        If you use our Services outside of India, your personal data may be transferred across borders. We ensure that such transfers comply with applicable legal standards, including the General Data Protection Regulation (GDPR) for users in the European Union.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Amendments to this Privacy Policy</p>
      <p className={companyPolicyStyle.text}>
        We reserve the right to update or modify this Privacy Policy at any time. Changes will be posted on this page, and it is your responsibility to review the updated policy periodically. Your continued use of our Services after any such changes constitutes your acceptance of the revised Privacy Policy.
      </p>

      <p className={companyPolicyStyle.sectionHeader}>Contact Information</p>
      <p className={companyPolicyStyle.text}>
        If you have any questions or concerns about this Privacy Policy, or if you wish to exercise any of your rights, please contact us at:
      </p>
      <p className={companyPolicyStyle.text}>
        Troibits Infotech Private Limited<br />
        Email: <a href="mailto:contact@troibits.com">contact@troibits.com</a><br />
        Phone: +91 9899500873<br />
        Address: 221 JMD Megapolis Sector 48, Gurugram, Haryana, India
      </p>

         <p className={companyPolicyStyle.sectionHeader}>Effective Date</p>
      <p className={companyPolicyStyle.text}>
        This Privacy Policy is effective as of 1 October 2024. Please review this policy periodically to stay informed about our practices regarding your personal information.
      </p>
    </div>
  );
};

export default CompanyPolicy;